import { removeTokens } from "~/utils/tokens/removeTokens";
import { setTokens } from "~/utils/tokens/setTokens";
import { getAccess } from "~/utils/tokens/getAccess";
import { getRefresh } from "~/utils/tokens/getRefresh";
import { normalize } from "~/utils/url/normalize";
import * as Sentry from "@sentry/vue";
import type {
  OrderHistory,
  PaginatedOrderHistoryList,
  Profile,
  TokenObtainRequest,
  TokenPair,
} from "~/api_gen";
import lodash from "lodash";

type SocialMethod =
  | "socialFacebookAuthCreate"
  | "socialGoogleAuthCreate"
  | "socialSteamAuthCreate"
  | "socialTelegramAuthCreate"
  | "socialVkAuthCreate"
  | "socialYandexAuthCreate";
type SocialUrlMethod =
  | "socialFacebookUrlRetrieve"
  | "socialGoogleUrlRetrieve"
  | "socialSteamUrlRetrieve"
  | "socialTelegramUrlRetrieve"
  | "socialVkUrlRetrieve"
  | "socialYandexUrlRetrieve";
type SocialBindMethod =
  | "socialSteamBindCreate"
  | "socialTelegramBindCreate"
  | "socialVkBindCreate"
  | "socialYandexBindCreate";
type SocialUnbindMethod =
  | "socialFacebookUnbindCreate"
  | "socialSteamUnbindCreate"
  | "socialTelegramUnbindCreate"
  | "socialVkUnbindCreate"
  | "socialYandexUnbindCreate";

export type SocialsIds = {
  vk_id: string | null;
  telegram_id: string | null;
  steam_id: string | null;
  facebook_id: string | null;
  yandex_id: string | null;
};

export const useUserStore = defineStore("userStore", () => {
  let TokenPair: TokenPair = { access: "", refresh: "" };
  const userData = ref<Profile | null>(null);
  const socialIds = ref<SocialsIds | null>(null);
  const userOrders = ref<PaginatedOrderHistoryList | null>(null);
  const lastOrders = ref<OrderHistory[]>([]);
  const showLastOrderPopup = ref(false);
  const authPopupIsRequired = ref(false);
  const userIsAuthorized = computed(() =>
    getAccess() && getRefresh() ? true : false,
  );
  const runtimeConfig = useRuntimeConfig();
  const baseURL = isClient()
    ? `${runtimeConfig.public.clientApiBase}api/`
    : `${runtimeConfig.public.serverApiBase}api/`;
  const orderPerPage = 50;
  // индикатор отображения модалки предупреждения удаления аккаунта
  const showUserDeletePopup = ref(false);

  function getAuthorizationHeader(): { Authorization: string } | {} {
    const access = isServer() ? TokenPair.access || getAccess() : getAccess();
    if (access) {
      return { Authorization: `Bearer ${access}` };
    } else {
      return {};
    }
  }

  function showAuthPopup() {
    document.documentElement.style.overflow = "hidden";
    authPopupIsRequired.value = true;
  }

  function hideAuthPopup() {
    authPopupIsRequired.value = false;
    document.documentElement.style.overflow = "";
  }

  function updateUserData(data: Profile) {
    userData.value = data;
  }

  function loginUsingCredentials(credentials: TokenObtainRequest) {
    return useApi().auth.jwtCreate({ tokenObtainRequest: credentials });
  }

  function logout() {
    userData.value = null;
    removeTokens();
    useProductsStore().deleteOrderFromStorage();
  }

  // Устанавливаем ID пользователя для виджета поддержки
  async function setUserIdTalkMe(userId: number): Promise<void> {
    const checkTalkMe = () => {
      if (window.TalkMe) {
        // Вызываем встроенный метод TalkMe для изменения данных клиента
        window.TalkMe("setClientInfo", {
          custom: {
            user_id: userId.toString(),
          },
        });
      } else {
        setTimeout(checkTalkMe, 500); // Проверяем снова через 500 мс
      }
    };
    checkTalkMe();
  }

  async function getUser() {
    const data = await useApi().auth.profileRetrieve();
    updateUserData(data);
    // Т.к. TalkMe использует window, при вызове на сервере
    // получает ошибку в Sentry. Поэтому ф-ию вызываем только на клиенте
    if (isClient()) setUserIdTalkMe(data.id);
  }

  function setLastOrders() {
    const curDate = new Date();
    const EXPIRATION_DATE = curDate.setDate(curDate.getDate() - 1);
    if (userOrders.value?.results) {
      lastOrders.value = userOrders.value.results.filter((order) => {
        return new Date(order.created).getTime() >= EXPIRATION_DATE;
      });
    }
  }

  async function getUserOrders() {
    try {
      const data = await useApi().orders.list({
        pageSize: orderPerPage,
        page: 1,
      });
      if (
        data &&
        "count" in data &&
        "next" in data &&
        "results" in data &&
        Array.isArray(data.results)
      ) {
        userOrders.value = data;
        setLastOrders();
      }
    } catch (error: any) {
      Sentry.captureException(error);
      throw error;
    }
  }

  function getSocialRedirectUri(social: string): string {
    const url = new URL(baseURL);
    return normalize(`${url.origin}/auth/${social}/redirect/`);
  }

  function getSocialAuthUrl(social: string, redirectUri: string) {
    const socialMethod =
      `social${lodash.capitalize(social)}UrlRetrieve` as SocialUrlMethod;
    return useApi().auth[socialMethod]({ redirectUri });
  }

  async function refreshTokens(refresh: string) {
    const data = await useApi().auth.jwtRefresh({
      tokenRefreshRequest: { refresh },
    });
    if (isServer()) TokenPair = data;
    setTokens(data);
  }

  function sendSocialCode(social: string, code: string, redirectUri: string) {
    const socialMethod =
      `social${lodash.capitalize(social)}AuthCreate` as SocialMethod;

    return useApi().auth[socialMethod]({
      socialAuthRequest: { code, redirectUri },
    });
  }

  function bindSocial(social: string, code: string, redirectUri: string) {
    const socialMethod =
      `social${lodash.capitalize(social)}BindCreate` as SocialBindMethod;
    return useApi().auth[socialMethod]({
      socialAuthRequest: { code, redirectUri },
    });
  }

  function unbindSocial(social: string) {
    const socialMethod =
      `social${lodash.capitalize(social)}UnbindCreate` as SocialUnbindMethod;
    return useApi().auth[socialMethod];
  }

  return {
    userData,
    socialIds,
    authPopupIsRequired,
    userIsAuthorized,
    userOrders,
    showUserDeletePopup,
    lastOrders,
    showLastOrderPopup,
    showAuthPopup,
    hideAuthPopup,
    updateUserData,
    setUserIdTalkMe,
    loginUsingCredentials,
    logout,
    getUser,
    getSocialRedirectUri,
    getSocialAuthUrl,
    refreshTokens,
    sendSocialCode,
    bindSocial,
    unbindSocial,
    getAuthorizationHeader,
    getUserOrders,
    orderPerPage,
  };
});
