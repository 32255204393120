// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GetPayNotify
 */
export interface GetPayNotify {
  /**
   *
   * @type {number}
   * @memberof GetPayNotify
   */
  wALLETID: number;
  /**
   *
   * @type {string}
   * @memberof GetPayNotify
   */
  wALLETTYPE: string;
  /**
   *
   * @type {string}
   * @memberof GetPayNotify
   */
  sUM: string;
  /**
   *
   * @type {string}
   * @memberof GetPayNotify
   */
  oRDERID: string;
  /**
   *
   * @type {string}
   * @memberof GetPayNotify
   */
  pTYPE: string;
  /**
   *
   * @type {string}
   * @memberof GetPayNotify
   */
  pWALLET: string;
  /**
   *
   * @type {string}
   * @memberof GetPayNotify
   */
  pAYMENTID: string;
  /**
   *
   * @type {string}
   * @memberof GetPayNotify
   */
  sIGN: string;
}

/**
 * Check if a given object implements the GetPayNotify interface.
 */
export function instanceOfGetPayNotify(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "wALLETID" in value;
  isInstance = isInstance && "wALLETTYPE" in value;
  isInstance = isInstance && "sUM" in value;
  isInstance = isInstance && "oRDERID" in value;
  isInstance = isInstance && "pTYPE" in value;
  isInstance = isInstance && "pWALLET" in value;
  isInstance = isInstance && "pAYMENTID" in value;
  isInstance = isInstance && "sIGN" in value;

  return isInstance;
}

export function GetPayNotifyFromJSON(json: any): GetPayNotify {
  return GetPayNotifyFromJSONTyped(json, false);
}

export function GetPayNotifyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetPayNotify {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    wALLETID: json["WALLET_ID"],
    wALLETTYPE: json["WALLET_TYPE"],
    sUM: json["SUM"],
    oRDERID: json["ORDER_ID"],
    pTYPE: json["P_TYPE"],
    pWALLET: json["P_WALLET"],
    pAYMENTID: json["PAYMENT_ID"],
    sIGN: json["SIGN"],
  };
}

export function GetPayNotifyToJSON(value?: GetPayNotify | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    WALLET_ID: value.wALLETID,
    WALLET_TYPE: value.wALLETTYPE,
    SUM: value.sUM,
    ORDER_ID: value.oRDERID,
    P_TYPE: value.pTYPE,
    P_WALLET: value.pWALLET,
    PAYMENT_ID: value.pAYMENTID,
    SIGN: value.sIGN,
  };
}
