import { withoutTrailingSlash } from "ufo";
import {
  Configuration,
  ResponseError,
  type ResponseContext,
  type ErrorContext,
} from "~/api_gen/runtime";
import type { TokenPair } from "~/api_gen/models";
import { Api } from "~/api_gen/apis";
import { AuthApi } from "~/api_gen/apis/AuthApi";
import { isServer } from "~/utils/isServer";
import { getRefresh } from "~/utils/tokens/getRefresh";
import { getAccess } from "~/utils/tokens/getAccess";
import { setTokens } from "~/utils/tokens/setTokens";
import * as Sentry from "@sentry/vue";

export class ApiGenManager extends Api {
  protected mutex: Promise<void> | undefined = undefined;
  protected tokenPair: TokenPair | undefined = undefined;

  protected override getConfig() {
    const basePath = this.getBasePath();
    const accessToken = this.getAccessToken.bind(this);
    const headers = this.getDefaultHeaders();
    const credentials = "include";
    const middleware = [
      {
        post: this.handle401ResponseStatus.bind(this),
      },
      {
        post: this.handleNoAccessResponse.bind(this),
      },
      {
        onError: this.handleFetchError.bind(this),
      },
    ];
    return new Configuration({
      basePath,
      accessToken,
      headers,
      credentials,
      middleware,
    });
  }

  protected getAccessToken() {
    if (isServer()) {
      return this.tokenPair?.access || getAccess() || "";
    } else {
      return getAccess() || "";
    }
  }

  protected getRefreshToken() {
    if (isServer()) {
      return this.tokenPair?.refresh || getRefresh() || "";
    } else {
      return getRefresh() || "";
    }
  }

  protected setTokenPair(tokenPair: TokenPair) {
    this.tokenPair = tokenPair;
  }

  protected getBasePath() {
    const runtimeConfig = useRuntimeConfig();
    if (isServer()) {
      const { serverApiBase } = runtimeConfig.public;
      if (serverApiBase) {
        return withoutTrailingSlash(serverApiBase);
      } else {
        console.warn("API_GEN: serverApiBase is not defined");
        return "http://localhost";
      }
    } else {
      const { clientApiBase } = runtimeConfig.public;
      if (clientApiBase) {
        return withoutTrailingSlash(clientApiBase);
      } else {
        return withoutTrailingSlash(window.location.origin);
      }
    }
  }

  protected getDefaultHeaders() {
    let headers: Record<string, string> = {};
    if (isServer()) {
      headers = Object.assign(headers, useRequestHeaders());
      if (!headers["X-Forwarded-For"] && !headers["x-forwarded-for"]) {
        const ip = useRequestEvent()?.node.req.socket.remoteAddress;
        if (ip) headers["X-Forwarded-For"] = ip;
      }
      if (headers["accept"]) {
        delete headers["accept"];
      } else if (headers["Accept"]) {
        delete headers["Accept"];
      }
      if (headers["content-type"]) {
        delete headers["content-type"];
      } else if (headers["Content-Type"]) {
        delete headers["Content-Type"];
      }
      if (
        process.env.CF_ACCESS_CLIENT_ID &&
        process.env.CF_ACCESS_CLIENT_SECRET
      ) {
        headers["CF-Access-Client-Id"] = process.env.CF_ACCESS_CLIENT_ID;
        headers["CF-Access-Client-Secret"] =
          process.env.CF_ACCESS_CLIENT_SECRET;
      }
    }
    return headers;
  }

  protected async handle401ResponseStatus({
    fetch,
    url,
    init,
    response,
  }: ResponseContext) {
    const refresh = this.getRefreshToken();
    if (response && response.status === 401 && refresh) {
      if (!this.mutex) {
        this.mutex = this.tryToRefreshTokens(refresh, init);
      }
      try {
        await this.mutex;
      } catch (error) {
        Sentry.captureException(error);
        return response;
      } finally {
        this.mutex = undefined;
      }
      try {
        const access = this.getAccessToken();
        if (!access)
          throw new Error(
            "There is no access token available to handle 401 status code",
          );
        const newInit = this.updateInitHeader(
          "Authorization",
          `Bearer ${access}`,
          init,
        );
        return await fetch(url, newInit);
      } catch (error) {
        Sentry.captureException(error);
        return response;
      }
    } else {
      return response;
    }
  }

  protected async handleNoAccessResponse({ response }: ResponseContext) {
    if (response) {
      try {
        const json = await response.clone().json();
        if (
          "code" in json &&
          "detail" in json &&
          json.code === "no_access" &&
          typeof json.detail === "string"
        ) {
          this.handleNoAccess(json.detail);
        }
      } catch (error) {
        // 201, 204 коды ответа... в данном случае ответа нет
      }
    }
    return Promise.resolve(response);
  }

  protected handleFetchError({ response }: ErrorContext) {
    if (response === undefined) {
      const altResponse = new Response(JSON.stringify({}), {
        status: 503,
        statusText: "Service Unavailable",
      });
      return Promise.resolve(altResponse);
    } else {
      return Promise.resolve(response);
    }
  }

  protected handleNoAccess(reason: string = "No access") {
    const userStore = useUserStore();
    userStore.logout();
    if (reason && !isServer()) {
      const notify = useNotify();
      notify({
        type: "error",
        text: reason,
      });
    }
  }

  protected updateInitHeader(
    header: string,
    value: string,
    init: RequestInit,
  ): RequestInit {
    const newInit = Object.assign({}, init);
    if (!newInit.headers) {
      throw new Error('There is no "headers" field in "init" object');
    }
    if (newInit.headers instanceof Headers) {
      newInit.headers.append(header, value);
    } else if (Array.isArray(newInit.headers)) {
      newInit.headers.push([header, value]);
    } else {
      newInit.headers[header] = value;
    }
    return newInit;
  }

  protected async tryToRefreshTokens(refresh: string, init: RequestInit) {
    let lastError;
    const authApiConfig = new Configuration({
      basePath: this.configuration.basePath,
    });
    const userApiInitOverrides = {
      credentials: this.configuration.credentials,
      headers: this.formRefreshRequestHeaders(init.headers),
    };
    const tokenRefreshArg = {
      tokenRefreshRequest: {
        refresh,
      },
    };
    const authApi = new AuthApi(authApiConfig);
    for (let i = 0; i < 5; i++) {
      try {
        const { access, refresh } = await authApi.jwtRefresh(
          tokenRefreshArg,
          userApiInitOverrides,
        );
        this.setTokenPair({ access, refresh });
        setTokens({ access, refresh });
        return;
      } catch (error: any) {
        lastError = error;
        if (error instanceof ResponseError && error.response.status === 400) {
          this.handleNoAccess();
          break;
        }
      }
    }
    throw lastError;
  }

  protected headersToObjectLiteral(headers?: HeadersInit) {
    if (!headers) {
      return {};
    }
    const result: Record<string, string> = {};
    if (headers instanceof Headers) {
      for (const [header, value] of headers.entries()) {
        result[header] = value;
      }
      return result;
    } else if (Array.isArray(headers)) {
      for (let i = 0; i < headers.length; i++) {
        const [header, value] = headers[i];
        result[header] = value;
      }
      return result;
    } else {
      return headers;
    }
  }

  protected formRefreshRequestHeaders(headers?: HeadersInit) {
    const result = this.headersToObjectLiteral(headers);
    const jsonMime = "application/json";
    if (!result["content-type"] && !result["Content-Type"]) {
      result["content-type"] = jsonMime;
    } else if (
      "content-type" in result &&
      result["content-type"] !== jsonMime
    ) {
      result["content-type"] = jsonMime;
    } else if (
      "Content-Type" in result &&
      result["Content-Type"] !== jsonMime
    ) {
      result["Content-Type"] = jsonMime;
    }
    if (!result["accept"] && !result["Accept"]) {
      result["accept"] = "*/*";
    }
    if ("Authorization" in result) {
      delete result["Authorization"];
    } else if ("authorization" in result) {
      delete result["authorization"];
    }
    return result;
  }
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      apiGenManager: new ApiGenManager(),
    },
  };
});
