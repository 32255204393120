// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Game } from "./Game";
import { GameFromJSON, GameFromJSONTyped, GameToJSON } from "./Game";
import type { Product } from "./Product";
import {
  ProductFromJSON,
  ProductFromJSONTyped,
  ProductToJSON,
} from "./Product";

/**
 *
 * @export
 * @interface PromoCode
 */
export interface PromoCode {
  /**
   *
   * @type {string}
   * @memberof PromoCode
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof PromoCode
   */
  percent: string;
  /**
   *
   * @type {Array<Game>}
   * @memberof PromoCode
   */
  readonly games: Array<Game>;
  /**
   *
   * @type {Array<Product>}
   * @memberof PromoCode
   */
  products: Array<Product>;
}

/**
 * Check if a given object implements the PromoCode interface.
 */
export function instanceOfPromoCode(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "code" in value;
  isInstance = isInstance && "percent" in value;
  isInstance = isInstance && "games" in value;
  isInstance = isInstance && "products" in value;

  return isInstance;
}

export function PromoCodeFromJSON(json: any): PromoCode {
  return PromoCodeFromJSONTyped(json, false);
}

export function PromoCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PromoCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    code: json["code"],
    percent: json["percent"],
    games: (json["games"] as Array<any>).map(GameFromJSON),
    products: (json["products"] as Array<any>).map(ProductFromJSON),
  };
}

export function PromoCodeToJSON(value?: PromoCode | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    code: value.code,
    percent: value.percent,
    products: (value.products as Array<any>).map(ProductToJSON),
  };
}
