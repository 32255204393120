// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { CurrencyEnum } from "./CurrencyEnum";
import {
  CurrencyEnumFromJSON,
  CurrencyEnumFromJSONTyped,
  CurrencyEnumToJSON,
} from "./CurrencyEnum";

/**
 *
 * @export
 * @interface MakePaymentRequest
 */
export interface MakePaymentRequest {
  /**
   *
   * @type {number}
   * @memberof MakePaymentRequest
   */
  orderId: number;
  /**
   *
   * @type {CurrencyEnum}
   * @memberof MakePaymentRequest
   */
  currency: CurrencyEnum;
  /**
   *
   * @type {string}
   * @memberof MakePaymentRequest
   */
  email?: string;
}

/**
 * Check if a given object implements the MakePaymentRequest interface.
 */
export function instanceOfMakePaymentRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "orderId" in value;
  isInstance = isInstance && "currency" in value;

  return isInstance;
}

export function MakePaymentRequestFromJSON(json: any): MakePaymentRequest {
  return MakePaymentRequestFromJSONTyped(json, false);
}

export function MakePaymentRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MakePaymentRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    orderId: json["order_id"],
    currency: CurrencyEnumFromJSON(json["currency"]),
    email: !exists(json, "email") ? undefined : json["email"],
  };
}

export function MakePaymentRequestToJSON(
  value?: MakePaymentRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    order_id: value.orderId,
    currency: CurrencyEnumToJSON(value.currency),
    email: value.email,
  };
}
