// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PaymentMethod } from "./PaymentMethod";
import {
  PaymentMethodFromJSON,
  PaymentMethodFromJSONTyped,
  PaymentMethodToJSON,
} from "./PaymentMethod";

/**
 *
 * @export
 * @interface PaymentGroup
 */
export interface PaymentGroup {
  /**
   *
   * @type {string}
   * @memberof PaymentGroup
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PaymentGroup
   */
  shortName: string;
  /**
   *
   * @type {number}
   * @memberof PaymentGroup
   */
  order: number;
  /**
   *
   * @type {Array<PaymentMethod>}
   * @memberof PaymentGroup
   */
  readonly methods: Array<PaymentMethod>;
}

/**
 * Check if a given object implements the PaymentGroup interface.
 */
export function instanceOfPaymentGroup(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "shortName" in value;
  isInstance = isInstance && "order" in value;
  isInstance = isInstance && "methods" in value;

  return isInstance;
}

export function PaymentGroupFromJSON(json: any): PaymentGroup {
  return PaymentGroupFromJSONTyped(json, false);
}

export function PaymentGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PaymentGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    shortName: json["short_name"],
    order: json["order"],
    methods: (json["methods"] as Array<any>).map(PaymentMethodFromJSON),
  };
}

export function PaymentGroupToJSON(value?: PaymentGroup | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    short_name: value.shortName,
    order: value.order,
  };
}
