// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PromoCode } from "./PromoCode";
import {
  PromoCodeFromJSON,
  PromoCodeFromJSONTyped,
  PromoCodeToJSON,
} from "./PromoCode";

/**
 *
 * @export
 * @interface Profile
 */
export interface Profile {
  /**
   *
   * @type {number}
   * @memberof Profile
   */
  readonly id: number;
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  nickname: string;
  /**
   *
   * @type {string}
   * @memberof Profile
   */
  avatarImg: string | null;
  /**
   *
   * @type {number}
   * @memberof Profile
   */
  readonly balance: number;
  /**
   *
   * @type {PromoCode}
   * @memberof Profile
   */
  readonly activePromoCode: PromoCode;
}

/**
 * Check if a given object implements the Profile interface.
 */
export function instanceOfProfile(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "nickname" in value;
  isInstance = isInstance && "avatarImg" in value;
  isInstance = isInstance && "balance" in value;
  isInstance = isInstance && "activePromoCode" in value;

  return isInstance;
}

export function ProfileFromJSON(json: any): Profile {
  return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Profile {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    email: json["email"],
    nickname: json["nickname"],
    avatarImg: json["avatar_img"],
    balance: json["balance"],
    activePromoCode: PromoCodeFromJSON(json["active_promo_code"]),
  };
}

export function ProfileToJSON(value?: Profile | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    nickname: value.nickname,
    avatar_img: value.avatarImg,
  };
}
