/* tslint:disable */
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ActivateCodeRequest,
  ActivateCodeResponse,
  BulldropOrderRequest,
  MooGoldOrderRequest,
  OrderResponse,
  PUBGOrderRequest,
  PaginatedMooGoldServersListList,
  PaginatedOrderHistoryList,
  StandoffOrderRequest,
  StandoffOrderSettings,
  StandoffOrderShort,
  VoucherOrderRequest,
} from "../models/index";
import {
  ActivateCodeRequestFromJSON,
  ActivateCodeRequestToJSON,
  ActivateCodeResponseFromJSON,
  ActivateCodeResponseToJSON,
  BulldropOrderRequestFromJSON,
  BulldropOrderRequestToJSON,
  MooGoldOrderRequestFromJSON,
  MooGoldOrderRequestToJSON,
  OrderResponseFromJSON,
  OrderResponseToJSON,
  PUBGOrderRequestFromJSON,
  PUBGOrderRequestToJSON,
  PaginatedMooGoldServersListListFromJSON,
  PaginatedMooGoldServersListListToJSON,
  PaginatedOrderHistoryListFromJSON,
  PaginatedOrderHistoryListToJSON,
  StandoffOrderRequestFromJSON,
  StandoffOrderRequestToJSON,
  StandoffOrderSettingsFromJSON,
  StandoffOrderSettingsToJSON,
  StandoffOrderShortFromJSON,
  StandoffOrderShortToJSON,
  VoucherOrderRequestFromJSON,
  VoucherOrderRequestToJSON,
} from "../models/index";

export interface OrdersApiBulldropRequest {
  bulldropOrderRequest: BulldropOrderRequest;
}

export interface OrdersApiListRequest {
  page?: number;
  pageSize?: number;
  status?: ListStatusEnum;
}

export interface OrdersApiPubgRequest {
  pUBGOrderRequest: PUBGOrderRequest;
}

export interface OrdersApiStandoffRequest {
  standoffOrderRequest: StandoffOrderRequest;
}

export interface OrdersApiStandoffAvatarRequest {
  id: number;
  email: string;
  gameAvatar?: Blob | null;
}

export interface OrdersApiSupplyChannelRequest {
  mooGoldOrderRequest: MooGoldOrderRequest;
}

export interface OrdersApiSupplyChannelServersListRequest {
  gameId: number;
  page?: number;
  pageSize?: number;
}

export interface OrdersApiVoucherRequest {
  voucherOrderRequest: VoucherOrderRequest;
}

export interface OrdersApiVoucherActivateRequest {
  activateCodeRequest: ActivateCodeRequest;
}

/**
 *
 */
export class OrdersApi extends runtime.BaseAPI {
  /**
   * Обработка нового заказа
   */
  async bulldropRaw(
    requestParameters: OrdersApiBulldropRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OrderResponse>> {
    if (
      requestParameters.bulldropOrderRequest === null ||
      requestParameters.bulldropOrderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "bulldropOrderRequest",
        "Required parameter requestParameters.bulldropOrderRequest was null or undefined when calling bulldrop.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/bulldrop/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BulldropOrderRequestToJSON(
          requestParameters.bulldropOrderRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderResponseFromJSON(jsonValue),
    );
  }

  /**
   * Обработка нового заказа
   */
  async bulldrop(
    requestParameters: OrdersApiBulldropRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OrderResponse> {
    const response = await this.bulldropRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Получение истории заказов
   */
  async listRaw(
    requestParameters: OrdersApiListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedOrderHistoryList>> {
    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedOrderHistoryListFromJSON(jsonValue),
    );
  }

  /**
   * Получение истории заказов
   */
  async list(
    requestParameters: OrdersApiListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedOrderHistoryList> {
    const response = await this.listRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Обработка нового заказа
   */
  async pubgRaw(
    requestParameters: OrdersApiPubgRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OrderResponse>> {
    if (
      requestParameters.pUBGOrderRequest === null ||
      requestParameters.pUBGOrderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "pUBGOrderRequest",
        "Required parameter requestParameters.pUBGOrderRequest was null or undefined when calling pubg.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/pubg/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PUBGOrderRequestToJSON(requestParameters.pUBGOrderRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderResponseFromJSON(jsonValue),
    );
  }

  /**
   * Обработка нового заказа
   */
  async pubg(
    requestParameters: OrdersApiPubgRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OrderResponse> {
    const response = await this.pubgRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Обработка нового заказа
   */
  async standoffRaw(
    requestParameters: OrdersApiStandoffRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StandoffOrderShort>> {
    if (
      requestParameters.standoffOrderRequest === null ||
      requestParameters.standoffOrderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "standoffOrderRequest",
        "Required parameter requestParameters.standoffOrderRequest was null or undefined when calling standoff.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/standoff/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StandoffOrderRequestToJSON(
          requestParameters.standoffOrderRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StandoffOrderShortFromJSON(jsonValue),
    );
  }

  /**
   * Обработка нового заказа
   */
  async standoff(
    requestParameters: OrdersApiStandoffRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StandoffOrderShort> {
    const response = await this.standoffRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Обработка нового заказа
   */
  async standoffAvatarRaw(
    requestParameters: OrdersApiStandoffAvatarRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OrderResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling standoffAvatar.",
      );
    }

    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        "email",
        "Required parameter requestParameters.email was null or undefined when calling standoffAvatar.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [
      { contentType: "multipart/form-data" },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.gameAvatar !== undefined) {
      formParams.append("game_avatar", requestParameters.gameAvatar as any);
    }

    if (requestParameters.email !== undefined) {
      formParams.append("email", requestParameters.email as any);
    }

    const response = await this.request(
      {
        path: `/api/orders/standoff/avatar/{id}/`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderResponseFromJSON(jsonValue),
    );
  }

  /**
   * Обработка нового заказа
   */
  async standoffAvatar(
    requestParameters: OrdersApiStandoffAvatarRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OrderResponse> {
    const response = await this.standoffAvatarRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async standoffSettingsRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StandoffOrderSettings>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/standoff/settings/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StandoffOrderSettingsFromJSON(jsonValue),
    );
  }

  /**
   */
  async standoffSettingsRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StandoffOrderSettings> {
    const response = await this.standoffSettingsRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   * Обработка нового заказа
   */
  async supplyChannelRaw(
    requestParameters: OrdersApiSupplyChannelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OrderResponse>> {
    if (
      requestParameters.mooGoldOrderRequest === null ||
      requestParameters.mooGoldOrderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "mooGoldOrderRequest",
        "Required parameter requestParameters.mooGoldOrderRequest was null or undefined when calling supplyChannel.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/supply_channel/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MooGoldOrderRequestToJSON(requestParameters.mooGoldOrderRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderResponseFromJSON(jsonValue),
    );
  }

  /**
   * Обработка нового заказа
   */
  async supplyChannel(
    requestParameters: OrdersApiSupplyChannelRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OrderResponse> {
    const response = await this.supplyChannelRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async supplyChannelServersListRaw(
    requestParameters: OrdersApiSupplyChannelServersListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedMooGoldServersListList>> {
    if (
      requestParameters.gameId === null ||
      requestParameters.gameId === undefined
    ) {
      throw new runtime.RequiredError(
        "gameId",
        "Required parameter requestParameters.gameId was null or undefined when calling supplyChannelServersList.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page_size"] = requestParameters.pageSize;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/supply_channel/servers/{game_id}/`.replace(
          `{${"game_id"}}`,
          encodeURIComponent(String(requestParameters.gameId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedMooGoldServersListListFromJSON(jsonValue),
    );
  }

  /**
   */
  async supplyChannelServersList(
    requestParameters: OrdersApiSupplyChannelServersListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedMooGoldServersListList> {
    const response = await this.supplyChannelServersListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Обработка нового заказа
   */
  async voucherRaw(
    requestParameters: OrdersApiVoucherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OrderResponse>> {
    if (
      requestParameters.voucherOrderRequest === null ||
      requestParameters.voucherOrderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "voucherOrderRequest",
        "Required parameter requestParameters.voucherOrderRequest was null or undefined when calling voucher.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("jwtAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/voucher/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: VoucherOrderRequestToJSON(requestParameters.voucherOrderRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderResponseFromJSON(jsonValue),
    );
  }

  /**
   * Обработка нового заказа
   */
  async voucher(
    requestParameters: OrdersApiVoucherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OrderResponse> {
    const response = await this.voucherRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Активация кода
   */
  async voucherActivateRaw(
    requestParameters: OrdersApiVoucherActivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ActivateCodeResponse>> {
    if (
      requestParameters.activateCodeRequest === null ||
      requestParameters.activateCodeRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "activateCodeRequest",
        "Required parameter requestParameters.activateCodeRequest was null or undefined when calling voucherActivate.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/voucher/activate/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ActivateCodeRequestToJSON(requestParameters.activateCodeRequest),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ActivateCodeResponseFromJSON(jsonValue),
    );
  }

  /**
   * Активация кода
   */
  async voucherActivate(
    requestParameters: OrdersApiVoucherActivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActivateCodeResponse> {
    const response = await this.voucherActivateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const ListStatusEnum = {
  Created: "created",
  Done: "done",
  Failed: "failed",
  Paid: "paid",
  Sent: "sent",
} as const;
export type ListStatusEnum =
  (typeof ListStatusEnum)[keyof typeof ListStatusEnum];
