// @ts-nocheck
/* eslint-disable */
/**
 * Gamelight API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  BaseProductTyped,
  instanceOfBaseProductTyped,
  BaseProductTypedFromJSON,
  BaseProductTypedFromJSONTyped,
  BaseProductTypedToJSON,
} from "./BaseProductTyped";
import {
  PUBGProductTyped,
  instanceOfPUBGProductTyped,
  PUBGProductTypedFromJSON,
  PUBGProductTypedFromJSONTyped,
  PUBGProductTypedToJSON,
} from "./PUBGProductTyped";
import {
  StandoffProductTyped,
  instanceOfStandoffProductTyped,
  StandoffProductTypedFromJSON,
  StandoffProductTypedFromJSONTyped,
  StandoffProductTypedToJSON,
} from "./StandoffProductTyped";
import {
  VoucherProductTyped,
  instanceOfVoucherProductTyped,
  VoucherProductTypedFromJSON,
  VoucherProductTypedFromJSONTyped,
  VoucherProductTypedToJSON,
} from "./VoucherProductTyped";

/**
 * @type Product
 *
 * @export
 */
export type Product =
  | ({ resourcetype: "BulldropProduct" } & BaseProductTyped)
  | ({ resourcetype: "PUBGProduct" } & PUBGProductTyped)
  | ({ resourcetype: "Product" } & BaseProductTyped)
  | ({ resourcetype: "StandoffProduct" } & StandoffProductTyped)
  | ({ resourcetype: "SupplyProduct" } & BaseProductTyped)
  | ({ resourcetype: "VoucherProduct" } & VoucherProductTyped);

export function ProductFromJSON(json: any): Product {
  return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Product {
  if (json === undefined || json === null) {
    return json;
  }
  switch (json["resourcetype"]) {
    case "BulldropProduct":
      return {
        ...BaseProductTypedFromJSONTyped(json, true),
        resourcetype: "BulldropProduct",
      };
    case "PUBGProduct":
      return {
        ...PUBGProductTypedFromJSONTyped(json, true),
        resourcetype: "PUBGProduct",
      };
    case "Product":
      return {
        ...BaseProductTypedFromJSONTyped(json, true),
        resourcetype: "Product",
      };
    case "StandoffProduct":
      return {
        ...StandoffProductTypedFromJSONTyped(json, true),
        resourcetype: "StandoffProduct",
      };
    case "SupplyProduct":
      return {
        ...BaseProductTypedFromJSONTyped(json, true),
        resourcetype: "SupplyProduct",
      };
    case "VoucherProduct":
      return {
        ...VoucherProductTypedFromJSONTyped(json, true),
        resourcetype: "VoucherProduct",
      };
    default:
      throw new Error(
        `No variant of Product exists with 'resourcetype=${json["resourcetype"]}'`,
      );
  }
}

export function ProductToJSON(value?: Product | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  switch (value["resourcetype"]) {
    case "BulldropProduct":
      return BaseProductTypedToJSON(value);
    case "PUBGProduct":
      return PUBGProductTypedToJSON(value);
    case "Product":
      return BaseProductTypedToJSON(value);
    case "StandoffProduct":
      return StandoffProductTypedToJSON(value);
    case "SupplyProduct":
      return BaseProductTypedToJSON(value);
    case "VoucherProduct":
      return VoucherProductTypedToJSON(value);
    default:
      throw new Error(
        `No variant of Product exists with 'resourcetype=${value["resourcetype"]}'`,
      );
  }
}
